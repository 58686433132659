'use strict';

var keyboardAccessibility = require('./keyboardAccessibility');
var categoryHandler = {};
var level2CategoryHandler = {};
var scrollLock = require('scroll-lock');

var clearSelection = function (element) {
    // $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
    //     .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    // $(element).closest('li').detach();
};

function loadSelectedSubMenu(element, mobile) {
    if(!categoryHandler[$(element).data('category')]){
        return new Promise(function (resolve, reject) {
            
            categoryHandler[$(element).data('category')] = true;
    
            if($(element).data('category')){
                $.ajax({
                    url: $('#fillMenuUrl').val(),
                    data: {catId: $(element).data('category'), hoverItem: true, mobile: mobile},
                    method: 'GET',
                    type: 'json',
                    success: function (response){
                        $(element).find('.mega-menu').append(response.template)
                        resolve(true);
                    },
                    error: function(response){
                        console.log('error: ', response)
                        reject(response);
                    }
                })
            }
        })
    }else{
        return new Promise(function (resolve, reject) {resolve(true)})
    }
}

async function loadRemainingSubMenus(element, elements, mobile) {
    
    for (let i = 0; i < elements.length; i++) {
        var tempelement = elements[i];
        var hoverItem = false;
        if(tempelement == element || !$(tempelement).data('category') || categoryHandler[$(tempelement).data('category')] || $(tempelement).find('mega-menu').children().length){
            continue;
        }
        if(!element){
            hoverItem = true;
        }
        categoryHandler[$(tempelement).data('category')] = true;
        await $.ajax({
            url: $('#fillMenuUrl').val(),
            data: {catId: $(tempelement).data('category'), hoverItem: hoverItem, mobile: mobile},
            method: 'GET',
            type: 'json',
            success: function (response){
                $(tempelement).find('.mega-menu').append(response.template)
            },
            error: function(response){
                console.log('error: ', response)
            }
        })
        
    }
}

function loadLevel2SubMenuMobile(element) {
    if(!level2CategoryHandler[$(element).data('category')]){
        return new Promise(function (resolve, reject) {
            
            level2CategoryHandler[$(element).data('category')] = true;
            var url = $('#fillSecondLevelUrl').val()
    
            if($(element).data('category')){
                $.ajax({
                    url: url,
                    data: {cgid: $(element).data('category'), hoverItem: false, mobile: true},
                    method: 'GET',
                    type: 'json',
                    success: function (response){
                        $(element).find('.row').append(response)
                        resolve(true);
                    },
                    error: function(response){
                        console.log('error: ', response)
                        reject(response);
                    }
                })
            }
        })
    }else{
        return new Promise(function (resolve, reject) {resolve(true)})
    }
}

async function loadRemainingLevel2SubMenusMobile(element, elements) {
    for (let i = 0; i < elements.length; i++) {
        var tempelement = elements[i];
        if(tempelement == element || !$(tempelement).data('category') || level2CategoryHandler[$(tempelement).data('category')] || $(tempelement).find('.mega-menu').length){
            continue;
        }
        level2CategoryHandler[$(tempelement).data('category')] = true;
        var url = $('#fillSecondLevelUrl').val()

        await $.ajax({
            url: url,
            data: {cgid: $(tempelement).data('category'), hoverItem: false, mobile: true},
            method: 'GET',
            type: 'json',
            success: function (response){
                $(tempelement).find('.row').append(response)
            },
            error: function(response){
                console.log('error: ', response)
            }
        })
        
    }
}

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                        .first()
                        .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $(document).on('click', '.main-menu .nav-link', async function(e) {
        if(window.innerWidth < 1365) {
            var loadAll = false;
            var elements;
            //if(!$(this).parent().find('.level-2').length && !$(this).closest('.menu-group').hasClass('level-2')) 
            if($(this).parents().hasClass('level-1-open') && $(this).parent().data('category')){
                e.preventDefault();
                var $selected = $(this).closest('.navbar-nav').find('.selected')
                if($selected){
                    $selected.removeClass('selected');
                }
                if($(this).parent().find('.menu-group.level-2').length && !$('#fillSecondLevelUrl').hasClass('loaded')){
                    $('#fillSecondLevelUrl').addClass('loaded');
                    elements = $(this).closest('.menu-group').find('.nav-item-level-1');
                    loadAll = true;
                }
                else if(!$('#fillSecondLevelUrl').hasClass('loaded')){
                    $('#fillSecondLevelUrl').addClass('loaded');
                    elements = $(this).closest('.menu-group').find('.nav-item-level-1');
                    await loadLevel2SubMenuMobile($(this).parent());
                    loadAll = true;
                }
                var $navbar = $('.navbar-light');
                $navbar.addClass('level-2-open');
                $navbar.removeClass('level-1-open');
                $(this).parent().addClass('selected');
                $(this).parent().find('.level-2').addClass('show');
                // $(this).parents('.main-menu').addClass('mobile-level-2-open');
                // $(this).parent().find('.level-2').addClass('level-2-submenu-mobile-open');
                if(loadAll){
                    loadRemainingLevel2SubMenusMobile($(this).parent()[0], elements);
                }
           //  }else if(!$(this).parent().find('.mega-menu').children().length && $(this).parent().data('category'))
            }else if($(this).parents().hasClass('level-2-open') && $(this).parent().data('category'))
            {
                e.preventDefault();
                var $selected = $(this).closest('.navbar-nav').find('.selected')
                if($selected){
                    $selected.removeClass('selected');
                }
                if(!$('#fillMenuUrl').hasClass('loaded') || !$(this).parent().find('.mega-menu').children().length){
                    if(!$('#fillMenuUrl').hasClass('loaded')){
                        $('#fillMenuUrl').addClass('loaded');
                    }
                    elements = $('.level-2 .nav-item');
                    await loadSelectedSubMenu($(this).parent(), true);
                    loadAll = true;
                }
                var $navbar = $('.navbar-light');
                $navbar.addClass('level-3-open');
                $navbar.removeClass('level-2-open');
                $(this).parent().addClass('selected');                
                // $(this).parents('.main-menu').addClass('mobile-megamenu-open');
                // $(this).parent().find('.mega-menu').addClass('submenu-mobile-open');
                if(loadAll){
                    loadRemainingSubMenus($(this).parent()[0], $('.level-2 .nav-item'), true);
                }
            }
        }
    });

    $('.main-menu .nav-item-level-1').hover(async function(e) {
            if(window.innerWidth >= 1365) {
                var width = $(this).width();
                var left = $(this).position().left;
                $('.nav-item-line.level-1').css({'width': width, 'left': left, 'transform': 'none'});
            }
        },function(e) {
            if(window.innerWidth >= 1365) {
                 if(!$(e.relatedTarget).parent().hasClass('nav-item-level-1')) {
                     $('.nav-item-line.level-1').css({'width': '', 'transform': ''});
                 }
                if(!$(e.relatedTarget).parent().hasClass('nav-item-level-2')) {
                    $('.nav-item-line.level-2').css({'width': '', 'transform': ''});
                }
            }
        }
    );

    // $('header > nav').hover(async function(e) {
    //     if(window.innerWidth >= 1365) {
    //         if(!$(e.relatedTarget).parent().hasClass('nav-item-level-1')) {
    //             $('.nav-item-line.level-1').css({'width': '', 'transform': ''});
    //         }
    //     }
    // });

    var menuTimeout;
    var menuHeight = 0;

    // To use for second category level //

    $(document).ready(function () {
        if(window.innerWidth >= 1365 && $('.level-2 .nav-item').length) {
            loadRemainingSubMenus(null, $('.level-2 .nav-item'));
            $('#fillMenuUrl').addClass('loaded')
        }
    })

    $('.level-2 .nav-item').hover(async function(e) {
        if(window.innerWidth >= 1365) {
            var loadAll = false;
            if(!$('#fillMenuUrl').hasClass('loaded')){
                $('#fillMenuUrl').addClass('loaded')
                await loadSelectedSubMenu(this);
                loadAll = true;
            }
            var width = $(this).width();
            var left = $(this).position().left;
            $('.nav-item-line.level-1').hide();
            $('.nav-item-line.level-2').css({'width': width, 'left': left, 'transform': 'none'});

            if($(this).find('.mega-menu .menu-links').length) {
                var self = $(this);
                if($(e.relatedTarget).parent().hasClass('nav-item')) {
                    //$(self).find('.mega-menu > .row').show();
                    $(self).find('.mega-menu').addClass('expanded').css('height', $(self).find('.mega-menu > .row').outerHeight() + 1);
                    $(self).siblings().find('.mega-menu').css({'height': $(self).find('.mega-menu > .row').outerHeight() + 1, 'visibility': ''});
                    $(self).find('.mega-menu').one('transitionend', function () {
                        $(self).find('.mega-menu').css('overflow', 'auto');
                    });
                    $(self).find('.mega-menu > .row').css('opacity', '1');
                    
                    if(!$('.modal-background').is(':visible')) {
                        $('.modal-background').fadeIn('fast');
                    }
                } else {
                    menuTimeout = setTimeout(function() {
                        //$(self).find('.mega-menu > .row').show();
                    $(self).find('.mega-menu').addClass('expanded').css('height', $(self).find('.mega-menu > .row').outerHeight() + 1);
                    $(self).siblings().find('.mega-menu').css({'height': $(self).find('.mega-menu > .row').outerHeight() + 1, 'visibility': ''});
                    $(self).find('.mega-menu').one('transitionend', function () {
                        $(self).find('.mega-menu').css('overflow', 'auto');
                    });
                    $(self).find('.mega-menu > .row').css('opacity', '1');
                        $('.modal-background').fadeIn('fast');
                    }, 150);
                }
            }  else {
                if($('.modal-background').is(':visible')) {
                    $('.modal-background').fadeOut('fast');
                }
            }
            if(loadAll){
                loadRemainingSubMenus(this, $('.level-2 .nav-item'));
            }
        }
    }, function(e) {
        if(window.innerWidth >= 1365) {
            if(!$(e.relatedTarget).parent().hasClass('nav-item-level-2')) {
                $('.nav-item-line.level-2').css({'width': '', 'transform': ''});
                $('.nav-item-line.level-1').show();
            }
            if($(this).find('.mega-menu .menu-links').length) {
                var self = $(this);
                clearTimeout(menuTimeout);
                $(self).find('.mega-menu > .row').css({'opacity': ''});
                if(!$(e.relatedTarget).parent().hasClass('nav-item')) {
                    $('.mega-menu').css('height','');
                    $('.mega-menu').css('overflow', '');
                    $(self).find('.mega-menu').one('transitionend', function () {
                        //$('.mega-menu > .row').hide();
                        $('.mega-menu').removeClass('expanded');
                    });

                    $('.modal-background').fadeOut('fast');
                } else {
                    //$('.mega-menu > .row').hide();
                        $('.mega-menu').removeClass('expanded');
                        $('.mega-menu').css('overflow', '');
                }
            }
        }
    });

    $(document).on('click', '.modal-background', function(e) {
        if($('.main-menu').hasClass('in')) {
            if($('.mega-menu').hasClass('submenu-mobile-open')) {
                $('.navbar-nav .close-button').click();
            } else {
                $('.navbar>.close-menu>.close-button').click();
            }
        }
    });

    $('.mega-menu .category-links .title > a').on('click', function(e) {
        if(window.innerWidth < 1365) {
            if($(this).parent().next().is('ul')) {
                e.preventDefault();
                var height = $(this).outerHeight(true);
                var top = $(this).offset().top + $(this).parents('.mega-menu').scrollTop();
                $(this).parents('.mega-menu').find('.mobile-title-line').css({'height': height, 'top': top, 'transform': 'none'});
                $('.mega-menu .category-links .menu-links').removeClass('show');
                $('.mega-menu .category-links .title').removeClass('active');
                $(this).parent().next().addClass('show');
                $(this).parent().addClass('active');
         //       $('.mega-menu').addClass('menu-links-open');
            }
        }
    });

    $(document)
        .on('click', '.dropdown:not(.disabled) [data-toggle="dropdown"]', function (e) {
            if(!$(this).parent().hasClass('clickable')) {
                if (!isDesktop(this)) {
                    $('.modal-background').show();
                    // copy parent element into current UL
                    var li = $('<li class="dropdown-item top-category" role="button"></li>');
                    var link = $(this).clone().removeClass('dropdown-toggle')
                        .removeAttr('data-toggle')
                        .removeAttr('aria-expanded')
                        .attr('aria-haspopup', 'false');
                    li.append(link);
                    var closeMenu = $('<li class="nav-menu"></li>');
                    closeMenu.append($('.close-menu').first().clone());
                    $(this).parent().children('.dropdown-menu')
                        .prepend(li)
                        .prepend(closeMenu)
                        .attr('aria-hidden', 'false');
                    // copy navigation menu into view
                    $(this).parent().addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    $(link).focus();
                    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                    e.preventDefault();
                }
            } else {
                e.preventDefault();

                if(!$(this).parent().hasClass('show')) {
                    $(this).parent().addClass('show');
                    $(this).siblings('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                } else {
                    $(this).parent().removeClass('show');
                    $(this).siblings('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                }
            }
        })
        .on('mouseenter', '.dropdown:not(.disabled) [data-toggle="dropdown"]', function () {
            if(!$(this).parent().hasClass('clickable')) {
                if (isDesktop(this)) {
                    var eventElement = this;
                    $('.navbar-nav > li').each(function () {
                        if (!$.contains(this, eventElement)) {
                            $(this).find('.show').each(function () {
                                clearSelection(this);
                            });
                            if ($(this).hasClass('show')) {
                                $(this).removeClass('show');
                                $(this).children('ul.dropdown-menu').removeClass('show');
                                $(this).children('.nav-link').attr('aria-expanded', 'false');
                            }
                        }
                    });
                    // need to close all the dropdowns that are not direct parent of current dropdown
                    $(this).parent().addClass('show');
                    $(this).siblings('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                }
            }
        })
        .on('mouseleave', '.dropdown:not(.disabled)', function () {
            if(!$(this).hasClass('clickable')) {
                if (isDesktop(this)) {
                    $(this).removeClass('show');
                    $(this).children('.dropdown-menu').removeClass('show');
                    $(this).children('.nav-link').attr('aria-expanded', 'false');
                }
            }
        });

    $('body').on('click', function(e) {
        if($('.dropdown.clickable').hasClass('show')) {
            if (!$(e.target).is('.dropdown.clickable') && $('.dropdown.clickable').has(e.target).length === 0) {
                $('.dropdown.clickable').removeClass('show');
                $('.dropdown.clickable').children('.dropdown-menu').removeClass('show');
                $('.dropdown.clickable .dropdown-toggle').attr('aria-expanded', 'false');
            }
        }
    });

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
      //  $('.mobile-title-line').css({'height': '', 'transform': ''});
        $('.menu-toggleable-left').removeClass('in');
        $('body').removeClass('show-chat-on-mobile');
        $('.menu-group').removeClass('show');
        $('.modal-background').hide();
        scrollLock.enablePageScroll();

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
    });

    $(document).on('click', '.menu-level-2 .level-2-back', function (e) {
        e.preventDefault();
        var $navbar = $('.navbar.level-2-open');
        $navbar.addClass('level-1-open').removeClass('level-2-open');
        $(this).closest('.nav-item').removeClass('selected');
    

        // clearSelection(this);    
        // $('.mobile-title-line').css({'height': '', 'transform': ''});
        // $('.main-menu').removeClass('mobile-megamenu-open');
        // $('.mega-menu').removeClass('submenu-mobile-open');
        // $('.mega-menu .category-links .menu-links').removeClass('show');
        // $('.mega-menu .category-links .title').removeClass('active');
     //   $('.mega-menu').removeClass('menu-links-open');
    });

    $(document).on('mouseleave', '.menu-level-2', function () {
        if($('.modal-background').is(':visible')) {
            $('.modal-background').fadeOut('fast');
        }
    });

    $(document).on('click', '.mega-menu .back', function (e) {   // Up from level 3 on mobile
        e.preventDefault();
        var $navbar = $('.navbar.level-3-open');

        $navbar.addClass('level-2-open').removeClass('level-3-open');
        $(this).closest('.nav-item').removeClass('selected');

        // clearSelection(this);    
        // $('.mobile-title-line').css({'height': '', 'transform': ''});
        // $('.main-menu').removeClass('mobile-megamenu-open');
        // $('.mega-menu').removeClass('submenu-mobile-open');
        // $('.mega-menu .category-links .menu-links').removeClass('show');
        // $('.mega-menu .category-links .title').removeClass('active');
      //  $('.mega-menu').removeClass('menu-links-open');
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
      //  $('.mobile-title-line').css({'height': '', 'transform': ''});
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');
        $('body').removeClass('show-chat-on-mobile');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');

        $('.modal-background').hide();
        scrollLock.enablePageScroll();
        // $('.main-menu').removeClass('mobile-megamenu-open');
        // $('.mega-menu').removeClass('submenu-mobile-open');
        // $('.mega-menu .category-links .menu-links').removeClass('show');
        // $('.mega-menu .category-links .title').removeClass('active');
      //  $('.mega-menu').removeClass('menu-links-open');
    });

    $('.navbar-toggler').click(async function (e) {
        e.preventDefault();
        if(window.innerWidth < 1365 && $('.main-menu').find('.level-3-open').length) {
            if(!$('#fillMenuUrl').hasClass('loaded') && !$('#fillMenuUrl').hasClass('burgerLoaded')){
                $('#fillMenuUrl').addClass('burgerLoaded');
                await loadSelectedSubMenu($('.menu-level-2').find('.selected'), true);
            }
        }
        $('.main-menu').addClass('in');
        $('.menu-group').addClass('show');
        $('body').addClass('show-chat-on-mobile');
        $('.modal-background').show();
        scrollLock.disablePageScroll();

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');

        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
    });

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('.navbar-header .user .account-link').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .user').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
        $('.user').attr('aria-expanded', 'false');
    });
    $('body').on('click', '#myaccount', function () {
        event.preventDefault();
    });
    $('body').on('click', '.custom-usp-trigger', function (e) {
        e.preventDefault();
            $('#'+$(this).attr('id')+'-modal').modal('show');
            $('body > .modal-backdrop').detach().appendTo('.custom-usp-wrapper');
    });

    var toTopBtn = $('#totopbutton');

    $(window).scroll(function() {
    if ($(window).scrollTop() > 300) {
        toTopBtn.addClass('show');
    } else {
        toTopBtn.removeClass('show');
    }
    });

    toTopBtn.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, '300');
    });
};
