'use strict';

var keyboardAccessibility = require('./keyboardAccessibility');

module.exports = function () {

    // $(document).ready(function(){
    //     console.log("test");
    //     $("country-selector option:selected").text($('#country-selector').val());
    // });

    //desktop show dropdownmenu
    $('.country-selector-current').click(function(e){
        e.preventDefault();
        if($('.country-selector-modal').is(':visible')) {
            $('.country-selector-modal').hide();
            $('.country-selector').removeClass('show');
            $('.country-selector').blur();
        } else {
            $('.country-selector-modal').show();
            $('.country-selector').trigger('focusin');
        }
    });

    //mobile show dropdownmenu
    $('.country-selector-current-mobile').click(function(e){
        e.preventDefault();
        if($('.country-selector-mobile-modal').is(':visible')) {
            $('.country-selector-mobile-modal').hide();
            $('.country-selector-mobile').removeClass('show');
        } else {
            $('.country-selector-mobile-modal').show();
            $('.country-selector-mobile').addClass('show');
        }
    });

    //desktop select new country
    $('.country-select').click(function(e){
        e.preventDefault();
        if($(this).hasClass('locale-select')){
            var localeCode = $(this).data('locale');
            var url = $(this).data('url');
            var data = {
                code: localeCode
            }
            if ($('.is-dashboard').length){
                data.extra = window.location.search
            }
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: data,
                success: function (response) {
                    if(response.success){
                        console.log('succes: ',response)
                        window.location.href = response.url
                    }
                },
                error: function (response) {
                    window.location.reload()
                }
            });
        }else{
            $('.country-confirm').attr('data-url', $(this).attr('data-url'));
            $('.country-selected').text($(this).text());
            $('.country-selector-modal').hide();
            $('.country-selector-confirm-modal').show();
        }
    });

    //mobile select new country
    $('.country-selector-mobile-modal').on('change', function(e) {
        var localeCheck = $(this).find('.locale-select')
        if($(localeCheck).length){
            var localeCode = $(this).find('.locale-select').not('.active').data('locale');
            var url = $(this).find('.locale-select').not('.active').data('url');

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {
                    code: localeCode
                },
                success: function (response) {
                    if(response.success){
                        console.log('succes: ',response)
                        window.location.href = response.url
                    }
                },
                error: function (response) {
                    window.location.reload()
                }
            });
        }else{
            $('.country-confirm-mobile').attr('data-url', $(this).find(':selected').attr('data-url'));
            $('.country-selected-mobile').text($(this).val());
            // $('.country-selector-mobile-modal').hide();
            $('.country-selector-confirm-mobile-modal').clone().appendTo('body');
            $('body > .country-selector-confirm-mobile-modal').modal('show');
        }
    });

    //desktop cancel switching site
    $('.country-selector-cancel').click(function(e){
        e.preventDefault();
        $('.country-selector-confirm-modal').hide();
        $('.country-confirm').attr('data-url', '');
        $('.country-selected').text('');
        $('.country-selector-modal').show();
    });

    //mobile cancel switching site
    $(document).on('click', '.country-selector-cancel-mobile', function(e){
        e.preventDefault();
        $('body > .country-selector-confirm-mobile-modal').modal('hide');
        $('.country-confirm-mobile').attr('data-url', '');
        $('.country-selected-mobile').text('');
        $('.country-selector-mobile-modal').show();
        $('.country-selector-mobile-modal').val($('.country-selector-mobile-modal option.active').text());
    });

    $('body').on('hidden.bs.modal', '.country-selector-confirm-mobile-modal', function () {
        $('body > .country-selector-confirm-mobile-modal').remove();
    });

    //close country selector on click outside
    $('body').on('click', function(e) {
        if ($('.country-selector-modal').is(':visible') || $('.country-selector-confirm-modal').is(':visible')) {
            if (!$(e.target).is('.country-selector') && $('.country-selector').has(e.target).length === 0) {
                $('.country-selector-modal').hide();
                $('.country-selector-confirm-modal').hide();
                $('.country-selector').removeClass('show');
            }
        }
    });

    //desktop show delivery country/prices for hovered country
    $('.country-select').hover(function(e){
        e.preventDefault();

        $('.country-selector-currency').text($(this).data('currency'));
        $('.country-selector-delivery').text($(this).data('delivery'));

        $('.country-selector-hover').show();

    },function(e){
        e.preventDefault();
        var activeCountry = $('.country-select.active');
        
        if(activeCountry.length > 0){
        	$('.country-selector-currency').text($('.country-select.active').data('currency'));
            $('.country-selector-delivery').text($('.country-select.active').data('delivery'));
        }
    });

    //desktop confirm use selected countries site
    $('.country-confirm').click(function (e) {
        e.preventDefault();
        
        window.location.href = $(this).data('url');

        // $.ajax({
        //     url: url,
        //     type: 'get',
        //     dataType: 'json',
        //     data: {
        //         code: localeCode,
        //         queryString: queryString,
        //         CurrencyCode: localeCurrencyCode,
        //         action: action
        //     },
        //     success: function (response) {
        //         $.spinner().stop();
        //         if (response && response.redirectUrl) {
        //             window.location.href = response.redirectUrl;
        //         }
        //     },
        //     error: function () {
        //         $.spinner().stop();
        //     }
        // });
    });
    //desktop confirm use selected countries site
    $(document).on('click', '.country-confirm-mobile',function (e) {
        e.preventDefault();

        window.location.href = $(this).data('url');
    });

    keyboardAccessibility('.navbar-header .country-selector',
        {
            40: function ($countryOptions) { // down
                if ($(this).is(':focus')) {
                    $countryOptions.first().focus();
                } else {
                    $(':focus').next().focus();
                }
            },
            38: function ($countryOptions) { // up
                if ($countryOptions.first().is(':focus') || $(this).is(':focus')) {
                    $(this).focus();
                    $(this).removeClass('show');
                } else {
                    $(':focus').prev().focus();
                }
            },
            27: function () { // escape
                $(this).focus();
                $(this).removeClass('show').children('.dropdown-menu').removeClass('show');
            },
            9: function () { // tab
                $(this).removeClass('show').children('.dropdown-menu').removeClass('show');
            }
        },
        function () {
            if (!($(this).hasClass('show'))) {
                $(this).addClass('show');
            }
            return $(this).find('.dropdown-country-selector').children('a');
        }
    );

    $('.navbar-header .country-selector').on('focusin', function () {
        $(this).addClass('show').children('.dropdown-menu').addClass('show');
    });
};
