'use strict';

module.exports = function () {
	var consentGiven = true;
	
	if(typeof CookieInformation !== "undefined" && !CookieInformation.getConsentGivenFor("cookie_cat_functional")){
		consentGiven = false;
	}
    var cookieSet = document.cookie.indexOf('dw_cookies_set_geo_locale_first_visit') >= 0;
    if(!cookieSet && consentGiven) {
        var urlContent = $('.page').data('url');
        var action = $('.page').data('action');
        var currentQuery = $('.page').data('querystring');
        if(urlContent){
            $.ajax({
                url: urlContent+"?action="+action+'&'+currentQuery,
                type: 'get',
                dataType: 'html'
            })
            .then(function (response, status, xhr) {
                if (!response){
                    document.cookie = 'dw_cookies_set_geo_locale_first_visit=1; path=/; max-age=2592000;';
                    return;
                }
                $('body').append(response);
                $('#country-detector').modal('show');
                $('#country-detector .decline, .close').click(function (e) {
                    e.preventDefault();
                    $('#country-detector').modal('hide');
                    $('#country-detector').remove();
                    document.cookie = 'dw_cookies_set_geo_locale_first_visit=1; path=/; max-age=2592000;';
                });
            });
        }
    }
};


