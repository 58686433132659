'use strict';

var productJqueryHelper = require('./productJqueryHelper');
var memberPromotionHelper = require('../components/memberPromotion');

function getStores(url, change, cart, shipmentUUID, shipmentID) {
    $.spinner().start()
    $.ajax({
        url: url,
        data: {change: change, cart: cart, shipmentUUID:shipmentUUID, shipmentID:shipmentID ,requestedQuantity: $('#quantity-1').val()},
        type: 'get',
        success: function (data) {
            if(data.error){
                productJqueryHelper.handlePostCartAdd(data);
            }else{
                $('#chooseClickAndCollectStoreModal').remove();
                $('#chooseClickAndCollectStoreModal').find('.modal-content').empty().append(data);
                $(data).appendTo('body');
                $("#chooseClickAndCollectStoreModal").modal("show");
                productJqueryHelper.lockbodyscroll(document.querySelector('#chooseClickAndCollectStoreModal .modal-body'));
                $("#chooseClickAndCollectStoreModal").on('hide.bs.modal', function(e) {
                    productJqueryHelper.clearbodyscroll();
                });
            }
            $.spinner().stop()
        },
        error: function (data) {
            $.spinner().stop()
        }
    })
}

function addToCCBasket(element) {
    var hashParams = {};
        // Loop through hash parameter, and creastes a key / value property for each.
    window.location.hash.substr(1).replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
        function ($0, $1, $2, $3) {
            hashParams[$1] = $3;
        }
    );

    var obj = {
        storeId: $(element).data('store'),
        pid: $(element).data('pid'),
        quantity: $('#quantity-1').val(),
        stockInStore: $(element).data('stockinstore'),
        plid: hashParams.list
    };
    $.spinner().start();
    $.ajax({
        url: $(element).data('url'),
        data: {
            storeId: $(element).data('store'),
            pid: $(element).data('pid'),
            quantity: $('#quantity-1').val(),
            stockInStore: $(element).data('stockinstore'),
            plid: hashParams.list
        },
        type: 'post',
        success: function (data) {
            $('#chooseClickAndCollectStoreModal').modal('hide');
            if(!$('.click-and-collect').attr('data-store')){
                $('.click-and-collect').attr('data-store', $(element).data('store'))
            }
            if(data.inventoryStatus){
                $('.inventorystatus-js').replaceWith(data.inventoryStatus);
            }
            productJqueryHelper.handlePostCartAdd(data);
            if (data.memberPromotionClass) {
                memberPromotionHelper.updateMemberPromotionFlag(data.memberPromotionClass);
            }
            $('body').trigger('product:afterAddToCart', data);
            if (data.addedProduct) {
                var dataLayer = data.addedProduct;
                for (var i = 0; i < dataLayer.length; i++) {
                    window.dataLayer.push(dataLayer[i]);
                }
            }
            productJqueryHelper.miniCartReportingUrl(data.reportingURL);
            if(!$('.inventorystatus-js').data('store')){
                $.ajax({
                    url: $('.inventorystatus-js').data('url'),
                    data: {storeId: $(element).data('store'),
                        pid: $(element).data('pid')},
                    type:"get",
                    success: function (response) {
                        $('.inventorystatus-js').replaceWith(response);
                        $.spinner().stop();
                    },
                    error: function name(response) {
                        console.log('error: ', response)
                        $.spinner().stop();
                    }
                })
            }else{
                $.spinner().stop();
            }

        },
        error: function (data) {
            $('#chooseClickAndCollectStoreModal').modal('hide');
            $.spinner().stop();
        }
    })
}

function changeCCStore(element, quantity){
    $.spinner().start();
    var cart =$(element).attr('data-cart');
    $.ajax({
        url: $(element).data('changeurl'),
        data: {storeId: $(element).data('store'), cart: $(element).attr('data-cart'), checkout: $(element).attr('data-checkout'), pid: $(element).attr('data-pid'), quantity: quantity},
        type: 'post',
        success: function (data) {
            $('#chooseClickAndCollectStoreModal').modal('hide');
            $('.click-and-collect').data('store', $(element).data('store'))
            if(data.inventoryStatus){
                $('.inventorystatus-js').replaceWith(data.inventoryStatus);
            }

            if(cart){
                $('.shipment-container').replaceWith(data.shipmentTemplate);
                $('.checkout-order-overview').replaceWith(data.orderOverviewTemplate);
            }else if(data.store){
                var cc = $('.selected-shipping-method.click-and-collect')
                var pickedUpMsg = cc.find('.header-wrapper').data('msg');
                cc.find('h6').empty().html(pickedUpMsg + ' ' + data.store.name)
                cc.find('.address-line-address1').empty().html(data.store.address1)
                cc.find('.address-line-postalCode').empty().html(data.store.postalCode)
                cc.find('.address-line-city').empty().html(data.store.city)
                cc.find('.address-line-country').empty().html(data.store.country)
            }else{
                $('.chosen-store').html(data.storename)

                if(data.addedProduct){
                    productJqueryHelper.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    if (data.addedProduct) {
                        var dataLayer = data.addedProduct;
                        for (var i = 0; i < dataLayer.length; i++) {
                            window.dataLayer.push(dataLayer[i]);
                        }
                    }
                    productJqueryHelper.miniCartReportingUrl(data.reportingURL);
                }

                
            }

            $.spinner().stop();
            
        },
        error: function (data) {
            $('#chooseClickAndCollectStoreModal').modal('hide');
            $.spinner().stop();
        }
    })
}

function convertLineItem(element, store, PLIUUID) {
    $.spinner().start();
    var url = $(element).data('carturl');
    var obj ={
        storeId: store ? store : $(element).data('store'),
        PLIUUID: PLIUUID
    }

    $.ajax({
        url: url,
        data: obj,
        type: 'post',
        success: function (data) {
            $('#chooseClickAndCollectStoreModal').modal('hide');
            if(!$('.click-and-collect').attr('data-store')){
                $('.click-and-collect').attr('data-store', $(element).data('store'))
            }
            if(data.error){
                if(data.cc){
                    var removeProduct = $(element).closest('.card.product-info').find('.remove-product');
                    confirmDelete(removeProduct);
                    $('#noStoreFoundModal .chosen-store').html($(element).data('storename'));
                    $('#noStoreFoundModal').modal('show');
                }else{
                    productJqueryHelper.handlePostCartAdd(data);
                }
            }else{
                $('.shipment-container').replaceWith(data.shipmentTemplate);
                $('.checkout-order-overview').replaceWith(data.orderOverviewTemplate);
            }
            $.spinner().stop();
        },
        error: function (data) {
            $('#chooseClickAndCollectStoreModal').modal('hide');
            $.spinner().stop();
        }
    })
}

function findNearbyStores(form) {
    $.ajax({
        url: $('.cc-allow-location').data('url'),
        type: 'get',
        data: form,
        datayType: 'JSON',
        success: function (response) {
            if(response.error){
                $('.stores-search').find('.invalid-feedback').empty().html(response.errorMsg)
                $('#searchNearbyStores').addClass('is-invalid');
                $('.instorepickup-storedetails').show();
                $('.available-stores-label').empty().html($('.available-stores-label').data('msg'));
                response.stores.forEach(store => {
                    if($('#'+store.ID).length){
                        $('#'+store.ID).show();
                    }
                });
            }else{
                if($('#searchNearbyStores').hasClass('is-invalid')){
                    $('#searchNearbyStores').removeClass('is-invalid')
                }
                $('.instorepickup-storedetails').hide();
                response.stores.forEach(store => {
                    if($('#'+store.ID).length){
                        $('#'+store.ID).show();
                    }
                });
                $('.available-stores-label').empty().html(response.availableStoresMsg);
            }
        },
        error: function (response) {
            console.log('error', response);
        }
    })
}

function confirmDelete(removeProduct) {

    var actionUrl = $(removeProduct).data('action');
    var productID = $(removeProduct).data('pid');
    var productName = $(removeProduct).data('name');
    var productBrand = $(removeProduct).data('brand');
    var uuid = $(removeProduct).data('uuid');
    var quantity = $(removeProduct).data('quantity');

    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);
    $deleteConfirmBtn.data('wishlist', false);
    $deleteConfirmBtn.data('quantity', quantity);
    
}

module.exports = {
    clickAndCollect: function () {
        $(document).on('click', '.click-and-collect', function(e) {
            e.preventDefault();
            var $this = $(this);
            var store = $this.attr('data-store');
            var cart = $this.attr('data-cart') ? true : '';
            if(cart && !store){
                if($('.shipments .shipment[data-cnc]').length){
                    store = $('.shipments .shipment[data-cnc]').data('cnc');
                }
            }
            var pid = $this.data('pid');
            var inStock = $this.data('instock');
            var shipmentUUID = $this.data('shipmentuuid');
            var shipmentID = $this.data('shipmentid');
            if(!inStock && !cart){
                var removeProduct = $(element).closest('.row').find('.remove-product')
                confirmDelete(removeProduct);
                $('#noStoreFoundModal .chosen-store').html($this.data('storename'))
                $('#noStoreFoundModal').modal('show')
            }else if(!store){
                getStores($(this).data('findstores'), false, cart, shipmentUUID, shipmentID);
            }else if(cart && store){
                var PLIUUID = $(this).closest('.shipment-'+$this.attr('data-shipmentuuid')).find('#CCLineItemUUID-'+$(this).attr('data-pid')).val()
                convertLineItem(this, store, PLIUUID);
            }else{
                addToCCBasket(this);
            }
        });

    },
    changeStore: function () {
        $(document).on('click', '.change-cc-store', function(e){
            e.preventDefault();
            getStores($(this).data('url'), true, $(this).data('cart'));
        })
    },
    convertFromClickAndCollect: function () {
        $(document).on('click', '.convert-from-cc', function (e) {
            e.preventDefault();
            convertLineItem(this, null, $(this).data('uuid'));
        })
    },
    showOpeningHours: function () {
        $(document).on('click', '.click-and-collect-showopeninghours', function(){
            $(this).closest('.instorepickup-storedetails').toggleClass('expanded');
        });
    },
    pickClickAndCollectStore: function () {
        $(document).on('click', '.choose-cc-store', function (e) {
            e.preventDefault();
            var current = $(this).data('current')
            if(current){
                $('#chooseClickAndCollectStoreModal').modal('hide');
            }
            else if($(this).data('change')){
                changeCCStore(this, $('#quantity-1').val());
            }else if($(this).data('cart')){
                convertLineItem(this, null, $(this).data('uuid'));
            }else{
                addToCCBasket(this);
            }
        })
    },
    modalButtons: function () {
      $(document).on('click', '#noStoreFoundModal .keep', function (e) {
          e.preventDefault();
          $('#noStoreFoundModal').modal('hide');
      })
      $(document).on('click', '#noStoreFoundModal .remove' ,function (e) {
        e.preventDefault();
        $('.cart-delete-confirmation-btn').trigger('click')
      })  
    },
    findStores: function (){
        $(document).on('click', '.find-nearby-stores', function() {
            var query = $('#searchNearbyStores').val();
            var $this = this

            var placeService = new google.maps.places.PlacesService($('#searchNearbyStores')[0]);

            var form = {};

            placeService.findPlaceFromQuery({'fields': ['ALL'], 'query': query}, function name(results, status) {

                if(status == google.maps.GeocoderStatus.OK) {
                    for (let i = 0; i < results.length; i++) {
                        var place = results[0];
                        if(place.geometry) {

    
                            form.lat = place.geometry.location.lat()
                            form.long = place.geometry.location.lng()
    
                            break;
                        }
                    }
                    form.pid =$($this).closest('.modal-body').find('.click-and-collect-stores :button').attr('data-pid');
                    findNearbyStores(form);
                }
            })
        })
    },
    findStoresByMyLocation: function () {
        $(document).on('click', '.cc-allow-location', function(){
            var address={}
            var $this = this;
            navigator.geolocation.getCurrentPosition(async function(pos) {

                var geocoder = new google.maps.Geocoder();
                
                var latlng = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);

                await geocoder.geocode({'location': latlng} , function(results, status){
                   
                    for (let i = 0; i < results.length; i++) {
                        var result = results[i]
                        if(result.address_components){
                            for (let j = 0; j < result.address_components.length; j++) {
                                if(result.address_components[j].types.indexOf('street_number') != -1){
                                    address.streetNumber = result.address_components[j].long_name
                                }
                                else if(result.address_components[j].types.indexOf('route') != -1){
                                    address.street = result.address_components[j].long_name
                                }
                                else if(result.address_components[j].types.indexOf('locality') != -1){
                                    address.city = result.address_components[j].long_name
                                }
                                else if(result.address_components[j].types.indexOf('postal_code') != -1){
                                   address.postalCode = result.address_components[j].long_name
                                }
                            }
                            if(address.postalCode){
                                break;
                            }
                        }
                    }
                })

                $('#searchNearbyStores').val(address.street + ' ' + address.streetNumber + ' ' + address.postalCode + ' ' + address.city)

                var form ={lat: pos.coords.latitude,
                    long: pos.coords.longitude,
                    pid: $($this).closest('.modal-body').find('.click-and-collect-stores :button').attr('data-pid')}
    
                findNearbyStores(form)
            })

        })
    },
    findNearByStoresTooltip: function() {
        // $(document).on('hover', '.cc-allow-location' , function(e) {
        //     $('.use-location-tooltip').show();
        // }, function(e) {
        //     $('.use-location-tooltip').hide();
        // })

        $(document).on('mouseenter','.cc-allow-location', function(e) {
            $('.use-location-tooltip').show();
        })
        $(document).on('mouseleave', '.cc-allow-location', function(e) {
            $('.use-location-tooltip').hide();
        })
    }
}