'use strict';

var createErrorNotification = require('./errorNotification');

function disableOtherPaymentTypes(){
    $('#selectedPaymentOption').val('GIFT_CERTIFICATE')
    // disable other payment types
    $('[data-method-id="AdyenPOS"]').attr('disabled','disabled').addClass('fuldGiftCardPayment').find('.active').removeClass('.active');
    $('[data-method-id="AdyenComponent"]').attr('disabled','disabled').addClass('fuldGiftCardPayment').find('.additionalFields').hide()
    $('[data-method-id="Santander"]').attr('disabled','disabled').addClass('fuldGiftCardPayment').find('.active').removeClass('.active');
    $('[data-method-id="Basic_Invoice"]').attr('disabled','disabled').addClass('fuldGiftCardPayment').find('.active').removeClass('.active');
    $('.payment-option-list input:checked').prop('checked', false); 
    $('.paymentHeader').addClass('dimmed');
}

function enableOtherPaymentTypes(){
    $('#selectedPaymentOption').val('GIFT_CERTIFICATE')
    // disable other payment types
    $('[data-method-id="AdyenPOS"]').attr('disabled', false).addClass('active').find('.fuldGiftCardPayment').removeClass('.fuldGiftCardPayment');
    $('[data-method-id="AdyenComponent"]').attr('disabled', false).removeClass('fuldGiftCardPayment').find('.fuldGiftCardPayment').show()
    $('[data-method-id="Santander"]').attr('disabled', false).addClass('active').find('.fuldGiftCardPayment').removeClass('.fuldGiftCardPayment');
    $('[data-method-id="Basic_Invoice"]').attr('disabled', false).addClass('active').find('.fuldGiftCardPayment').removeClass('.fuldGiftCardPayment');
    $($('.payment-option-list input:radio')[0]).prop('checked', true);

    $('.paymentHeader').removeClass('dimmed');
}

function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

module.exports={
    couponCode: function () {
        
        $(document).on('click', '.show-coupon-code-input', function (e) {
            $(this).closest('.show-coupon').addClass('open');
            $('.add-coupon').show();
        })

        $(document).on('submit', '.add-coupon .coupon-code-form', function (e) {
            e.preventDefault();
            $.spinner().start();
            $('.coupon-missing-error').hide();
            $('.coupon-error-message').empty();
            if (!$('.coupon-input:visible').val()) {
                $('.coupon-code-form .form-control').addClass('is-invalid');
                $('.coupon-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
                $('.coupon-missing-error').show();
                $.spinner().stop();
                return false;
            }
            var $form = $('.coupon-code-form:visible');
            $('.coupon-code-form .form-control').removeClass('is-invalid');
            $('.coupon-error-message').empty();

            var cart = $(this).data('cart')

            $.ajax({
                url: $form.attr('action'),
                type: 'GET',
                data: $form.serialize(),
                success: function (data) {
                    if (data.error) {
                        $('.coupon-code-form .form-control').addClass('is-invalid');
                        $('.coupon-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                        $('.coupon-error-message').empty().append(data.errorMessage);
                        $('body').trigger('promotion:error', data);
                    } else if(!cart) {
                        if(!$('.remove-coupon').children().length){
                            var tmpl = $('.remove-coupon-template').clone();
                            $('.col-6', tmpl).attr('data-coupon', data.order.totals.couponPriceAdjustments[0].code)
                            $('.coupon-code', tmpl).text(data.order.totals.couponPriceAdjustments[0].code);
                            $('.remove-coupon-code', tmpl).attr('data-uuid', data.order.totals.couponPriceAdjustments[0].UUID);
                            $('.remove-coupon-code', tmpl).attr('data-code', data.order.totals.couponPriceAdjustments[0].code);
                            $('.coupon-discount-amount', tmpl).text(data.order.totals.couponPriceAdjustments[0].formattedPrice);
                            $('.remove-coupon').append(tmpl.html());
                            
                        }else{
                            for (let i = 0; i < data.order.totals.couponPriceAdjustments.length; i++) {
                                if(!$('.remove-coupon').find('.remove-coupon-code[data-code="'+data.order.totals.couponPriceAdjustments[i].code+'"]').length){
                                    var tmpl = $('.remove-coupon-template').clone();
                                    $('.col-6', tmpl).attr('data-coupon', data.order.totals.couponPriceAdjustments[i].code)
                                    $('.coupon-code', tmpl).text(data.order.totals.couponPriceAdjustments[i].code);
                                    $('.remove-coupon-code', tmpl).attr('data-uuid', data.order.totals.couponPriceAdjustments[i].UUID);
                                    $('.remove-coupon-code', tmpl).attr('data-code', data.order.totals.couponPriceAdjustments[i].code);
                                    $('.coupon-discount-amount', tmpl).text(data.order.totals.couponPriceAdjustments[i].formattedPrice);
                                    $('.remove-coupon').append(tmpl.html());
                                    break;
                                } else {
                                    $('.remove-coupon-code', tmpl).attr('data-uuid', data.order.totals.couponPriceAdjustments[i].UUID);
                                }
                            }
                        }
                        $('.add-coupon').hide();
                        $('.show-coupon').removeClass('open').hide();
                        $('.remove-coupon').show();
                        if(data.giftCardResult){
                            if (data.giftCardResult.giftCardInFull){
                                disableOtherPaymentTypes();
                            }
                            $('.hide-gift-cert-total').removeClass('hide-gift-cert-total')
                            $('.gift-cert-total-sum').text("- " + data.giftCardResult.giftCardAttr.total)
                            $('.grand-total-sum').text(data.giftCardResult.giftCardAttr.grandTotal)
                            $('#gift-cert-used').text(data.giftCardResult.giftCardAttr.used)
                            $('#gift-cert-balance').text(data.giftCardResult.giftCardAttr.balance)
                            $('#gift-cert-left').text(data.giftCardResult.giftCardAttr.left)
                            $('.gift-cert-success').show()
                            $('.gift-cert-error').hide()
                        }
                        $('body').trigger('checkout:updateCheckoutView',
                        {
                            order: data.order,
                            customer: data.customer
                        });
                    }else{
                        $('.shipment-container').replaceWith(data.shipmentTemplate);
                        $('.checkout-order-overview').replaceWith(data.orderOverviewTemplate);
                    }
                    $('.coupon-input').val('');
                    $.spinner().stop();
                },
                error: function (err) {
                    $('body').trigger('promotion:error', err);
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
            return false;
        });

        $('body').on('click', '.remove-coupon-code', function (e) {
            e.preventDefault();
    
            var couponCode = $(this).attr('data-code');
            var uuid = $(this).attr('data-uuid');
            var cart = $(this).attr('data-cart');
            var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
            var $productToRemoveSpan = $('.coupon-to-remove');
    
            $deleteConfirmBtn.attr('data-uuid', uuid);
            $deleteConfirmBtn.attr('data-code', couponCode);
            $deleteConfirmBtn.attr('data-cart', cart);
    
            $productToRemoveSpan.empty().append(couponCode);
            $('#removeCouponModal').modal('show');
        });
    
        $('body').on('click', '#removeCouponModal .delete-coupon-confirmation-btn', function (e) {
            e.preventDefault();
    
            var url = $(this).data('action');
            var uuid = $(this).attr('data-uuid');
            var couponCode = $(this).attr('data-code');
            var cart = $(this).attr('data-cart');
            var urlParams = {
                code: couponCode,
                uuid: uuid,
                cart: cart
            };
    
            url = appendToUrl(url, urlParams);
    
            $('body > .modal-backdrop').remove();
    
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                success: function (data) {
                    if(cart){
                        $('.shipment-container').replaceWith(data.shipmentTemplate);
                        $('.checkout-order-overview').replaceWith(data.orderOverviewTemplate);
                    }else{
                        $('body').trigger('checkout:updateCheckoutView',
                        {
                            order: data.order,
                            customer: data.customer
                        });
                        if(data.enablePayMentMethods){
                            enableOtherPaymentTypes()
                        }
                        $('.show-coupon').show();
                        $('.remove-coupon').find($('.remove-coupon').find('*[data-coupon="'+couponCode+'"]')).remove();
                        $('.remove-coupon').hide();
                        $('#addCouponToggle').prop('checked', false);

                        if($('#addCouponToggle:checked').length){
                            $('.payment-promo').removeClass('d-none')
                            $('.add-coupon').show();
                        }
                    }
                    $.spinner().stop();
                },
                error: function (err) {
                    $('body').trigger('promotion:error', err);
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        });
    }
}