'use strict';

var formValidation = require('../components/formValidation');
var createErrorNotification = require('../components/errorNotification');
var validator = require('../validator');

function submitLogin(e, form, url){
    console.log('submitting login form');
    form.spinner().start();
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            form.spinner().stop();
            if (!data.success) {
                formValidation(form, data);
                $('#loginModal form.login').trigger('login:error', data);
            } else {
                $('#loginModal form.login').trigger('login:success', data);
                location.href = data.redirectUrl;
            }
        },
        error: function (data) {
            if (data.responseJSON.redirectUrl) {
                window.location.href = data.responseJSON.redirectUrl;
            } else {
                $('#loginModal form.login').trigger('login:error', data);
                form.spinner().stop();
            }
        }
    });
    return false;
}

function bindLoginModal() {
	//Move login and reset modals, in order to get in front of backdrop.
	$('#loginModal').detach().appendTo('body');
	$('#requestPasswordResetModal').detach().appendTo('body');

	
	$(document).on('click', '.loginbtn',function (e) {
		e.preventDefault();
		$('#loginModal').modal('show');

        
        $('#loginModal').find('.login').attr('name', 'form.login.modal');


	});
	
	//Create account button
	$('#loginModal button.btn-secondary').on('click', function (e) {
		var url = $(this).data('href');
		location.href = url;
	});
	
	$('#loginModal #password-reset').on('click', function (e) {
		e.preventDefault();
		if ($('#loginModal').length !== 0) {
			$('#loginModal').modal('hide');
	    }
		
		if ($('#requestPasswordResetModal').length !== 0) {
			$('#requestPasswordResetModal').modal('show');
	    }
	});
	
    $('#loginModal form.login.modal').submit(function (e) {
        e.preventDefault()
        var form = $(this);
        var url = form.attr('action');
        $('#loginModal form.login').trigger('login:submit', e);
        submitLogin(e,form,url)
    });
    //   ILB-474 known customer, removed
    /*
    $('#knownCustomerLogin').on('click',function (e) {
        e.preventDefault()
        var form = $('.yourinfo-form form')
        var url = $('#knownCustomerLogin').attr('data-checkout-get-url')
        $('.known-customer-login').trigger('login:submit', e);
        submitLogin(e,form,url)
        return false;
    });
    */
}

function bindResetPasswordModel() {
	$('.reset-password-form').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $('.reset-password-form').trigger('login:register', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                } else {
                    $('.request-password-title').text(data.receivedMsgHeading);
                    $('.request-password-body').empty()
                        .append('<p>' + data.receivedMsgBody + '</p>');
                    if (!data.mobile) {
                        $('#submitEmailButton').text(data.buttonText)
                            .attr('data-dismiss', 'modal');
                        
                        $('#requestPasswordResetModal #submitEmailButton').on('click', function(e) {
                        	e.preventDefault();
                        	if ($('#requestPasswordResetModal').length !== 0) {
                    			$('#requestPasswordResetModal').modal('hide');
                    	    }
                        	
                        	if ($('#loginModal').length !== 0) {
                    			$('#loginModal').modal('show');
                    	    }
                        });
                    } else {
                        $('.send-email-btn').empty()
                            .html('<a href="'
                                + data.returnUrl
                                + '" class="btn btn-primary btn-block">'
                                + data.buttonText + '</a>'
                            );
                        $('#requestPasswordResetModal a.btn-primary').on('click', function(e) {
                        	e.preventDefault();
                        	if ($('#requestPasswordResetModal').length !== 0) {
                    			$('#requestPasswordResetModal').modal('hide');
                    	    }
                        	
                        	if ($('#loginModal').length !== 0) {
                    			$('#loginModal').modal('show');
                    	    }
                        });
                    }
                }
            },
            error: function () {
                form.spinner().stop();
            }
        });
        return false;
    });
}

module.exports = {
    login: function () {
        $('form.login').submit(function (e) {
            if($(this).find('.alert-danger')){
                $(this).find('.alert-danger').remove();
            }
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                    } else {
                        $('form.login').trigger('login:success', data);
                        // Redirect back to previous page
                        if (document.referrer.indexOf('illumsbolighus') > -1 && data.redirectingToPreviousPage && data.redirectIndex) {
                            window.history.go(data.redirectIndex);
                        } else {
                            location.href = data.redirectUrl;
                        }
                    }
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        form.spinner().stop();
                    }
                }
            });
            return false;
        });

        $('form.login').on('click', '#createAccountRegistration', function (e) {
            var url = $(this).data('href');
            location.href = url;
        });

        bindLoginModal();
    },

    logout: function () {
        $('form.logout').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');

            $.ajax({
                url: url,
                success: function (data) {
                    // Redirect back to previous page
                    window.history.go(data.redirectIndex);
                },
                error: function (data) {
                    // Error handling

                }
            });

            return false;
        });
        
        $('.loyalty-club.existing-user .secondary-link').on('click', function (e) {
            e.preventDefault();
            var url = $(this).attr('href');
            $.ajax({
                url: url,
                success: function (data) {
                    // Redirect back to previous page
                    window.history.go(data.redirectIndex);
                },
                error: function (data) {
                    // Error handling

                }
            });
        });
    },

    register: function () {
        validator.init(); 
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.registration').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        $('form.registration').trigger('login:register:error', data);
                        formValidation(form, data);
                        if(data.reCaptchaError){
                            $('.recaptcha-error').show();
                        }else if(data.errorMessage) {
                            $('.error-message').show();
                            if(data.loginerror){
                                $('.error-message-text').html(data.errorMessage+'. '+ '<a href='+data.url+' class="loginbtn"><strong>'+data.link+'</strong></a>');
                            }else{
                                $('.error-message-text').text(data.errorMessage);
                            }
                        }
                    } else {
                        $('form.registration').trigger('login:register:success', data);
                        // Redirect back to previous page
                        if (document.referrer.indexOf('illumsbolighus') > -1 && data.redirectingToPreviousPage) {
                            window.history.go(data.redirectIndex);
                        } else {
                            location.href = data.redirectUrl;
                        }
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
    	bindResetPasswordModel();
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    },

    clearErrorMessages: function(){
        $('#registration-form-email').on('focus', function(){
            $(this).removeClass('is-invalid');
            $('#form-email-error').text("");
        })
        
        $('#registration-form-password').on('focus', function(){
            $(this).removeClass('is-invalid');
            $('#form-password-error').text("");
        })

        $('#registration-form-password-confirm').on('focus', function(){
            $(this).removeClass('is-invalid');
            $('#form-password-confirm-error').text("");
        })

        $('#registration-form-fname').on('focus', function(){
            $(this).removeClass('is-invalid');
            $('#form-fname-error').text("");
        })

        $('#registration-form-lname').on('focus', function(){
            $(this).removeClass('is-invalid');
            $('#form-lname-error').text("");
        })

        $('#registration-form-phone').on('focus', function(){
            $(this).removeClass('is-invalid');
            $('#form-phone-error').text("");
        })

        $('#registration-no1-terms').on('focus', function(){
            $(this).removeClass('is-invalid');
            $('#form-terms-error').text("");
        })
    },

    no1Login: function(){
        $('.loginNo1').on('click', function (e) {
            e.preventDefault();
            $('.loginbtn').trigger('click');
        })
    },
    
    openProductDropdown: function () {
        $(document).on('click', '.shipment-product-summary-block', function (e) {
            if (jQuery.inArray(e.target.className, ["shipment-product-summary-block", "shipment-products-dropdown", "icon-down", "icon-up", "products-text"]) !== -1 || e.target.nodeName === 'IMG'){
                if(!$(this).hasClass('active')){
                    $(this).find('.icon-down').hide();
                    $(this).find('.icon-up').show();
                    $(this).find('.shipment-products').show();
                    $(this).addClass('active');
                }else{
                    $(this).find('.icon-down').show();
                    $(this).find('.icon-up').hide();
                    $(this).find('.shipment-products').hide();
                    $(this).removeClass('active');
                }
            }
            
        });
        $(document).on('click', '.product-summary-block-dropdown', function (e) {
            if(!$(this).hasClass('active')){
                $(this).find('.icon-down').hide();
                $(this).find('.icon-up').show();
                $('.product-summary-block').show();
                $(this).addClass('active');
            }else{
                $(this).find('.icon-down').show();
                $(this).find('.icon-up').hide();
                $('.product-summary-block').hide();
                $(this).removeClass('active');
            }
            $(document.body).trigger("sticky_kit:recalc");
        });
    }
};
