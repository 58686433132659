'use strict';

var processInclude = require('../util');

function initCarouselSlider() {
    var containerWidth =  $(this).width();
    var carouselWidth = $(this).find(".owl-carousel").width();
    var scrollContWidth = $(this).siblings(".progress-wrapper").find(".progress-container").width();
    var scrollBarWidth = (containerWidth / carouselWidth) * scrollContWidth;

    $(this).siblings(".progress-wrapper").find(".progress-bar").css( "width", scrollBarWidth );

}

$(".slide-on-mobile .carousel-wrapper" ).on("scroll", function() {
    var containerWidth =  $(this).width();
    var carouselWidth = $(this).find(".owl-carousel").width();
    var scrolledDist = $(this).find(".owl-carousel").position().left - 15;
    var scrollContWidth = $(this).siblings(".progress-wrapper").find(".progress-container").width();
    var scrolledRelative = -1 * scrolledDist * scrollContWidth / carouselWidth;
    var scrollBarWidth = (containerWidth / carouselWidth) * scrollContWidth;

    // Hack when size has not been initialized due to hidden carousel..eg. on modal
    if ($(this).siblings(".progress-wrapper").find(".progress-bar").width() == 60) {   
        $(this).siblings(".progress-wrapper").find(".progress-bar").css( "width", scrollBarWidth );    
    };

 //   console.log('scrolled, ' +  containerWidth + ", " + scrollContWidth + ", " + scrollBarWidth + ", " + carouselWidth + ", " + scrolledDist + ", " + scrolledRelative);

    $(this).siblings(".progress-wrapper").find(".progress-bar").css( "left", scrolledRelative );
});

function updateCarousels() {
   // Owl carousel setup
   if ($(window).width() > 767) {

        $('.feature-carousel.owl-carousel').on('initialized.owl.carousel changed.owl.carousel', function(e) {
            if (!e.namespace)  {
            return;
            }
            var carousel = e.relatedTarget;
            //var wrapper = $(this).parent('.feature-wrapper');

    //           console.log('test: ' + carousel.current() );
            // if (carousel.current() > (carousel.items().length + 1)) {
            //     $(this).addClass("end");
            // }
            // else {
            //     $(this).removeClass("end");
            // }
            if (carousel.current() == 3) {
                $(this).addClass("start");
            }
            else {
                $(this).removeClass("start");
            }            
            $('.carousel-counter').text(carousel.relative(carousel.current()) + 1 + '/' + carousel.items().length);

        }).owlCarousel({
            items: 3,
            nav: true,
            dots: false,
            navText: "",
            loop: true,
            margin: 20,
            responsive: {
                0: {
                items: 2,
                },
                1024: {
                items: 3
                }
            },
        });

        $('.product-carousel.owl-carousel').each(function(){
            var $this = $(this);
            var nrOfItems = $this.find('.carousel-item-wrapper').length;
            var screenSize = $(window).width();
            var specialCase = $('.cart-inline-recomm .owl-carousel')
            var temp0 = (specialCase.length != 0 && $this.is(specialCase)) ? 2 : 3
            var temp1024 = (specialCase.length != 0 && $this.is(specialCase)) ? 2 : 4
            if ((specialCase.length != 0 && $this.is(specialCase) && nrOfItems > 2) || (screenSize > 1006 && nrOfItems > 4) || (screenSize <= 1006 && nrOfItems > 3)) {
                $this.removeClass('no-carousel');
                $this.owlCarousel({
                    items: 4,
                    nav: true,
                    dots: false,
                    navText: "",
                    loop: true,
                    slideBy: 4,
                    responsive: {
                        0: {
                            items: temp0,
                            slideBy: temp0              
                        },
                        1024: {
                            items: temp1024,
                            slideBy: temp1024
                        }
                    },
                    margin: 20
                }).addClass("looping");        
            } else {
                $this.owlCarousel('destroy');
                $this.addClass('no-carousel');
            }
        });

        $('.modal-carousel.owl-carousel').each(function(){
            var nrOfItems = $(this).find('.carousel-item-wrapper').length;
            var screenSize = $(window).width();
            if (screenSize > 767 && nrOfItems > 3) {
                $(this).removeClass('no-carousel');
                $(this).owlCarousel({
                    items: 3,
                    nav: true,
                    dots: false,
                    navText: "",
                    loop: true,
                    slideBy: 3,
                    margin: 30
                }).addClass("looping");        
            } else {
                $(this).owlCarousel('destroy');
                $(this).addClass('no-carousel');
            }
        });
        
    } else {
        $('.feature-carousel.owl-loaded, .product-carousel.owl-loaded, .modal-carousel.owl-loaded').owlCarousel('destroy'); 
        $(".slide-on-mobile .carousel-wrapper").each( initCarouselSlider );
    }
};

function updateAccordion() {
    var parentId = '#' + $(this).attr('id');
    $(this).find('.content').attr('data-parent', parentId);
    if ($(window).width() > 767) {
        $(this).find('.accordion-item:first-child .title').trigger('click');
    }
};

$(document).ready(function () {
    updateCarousels();
    $('.custom-accordion').each( updateAccordion );

    $(window).resize(function () {
        updateCarousels();
    });
});

