window.jQuery = window.$ = require('jquery');
var processInclude = require('./util');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/cookie'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/powerStep'));
    processInclude(require('./components/cookieinformation'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/toolTip'));
    processInclude(require('./login/login'));
    processInclude(require('./components/countryDetector'));
    processInclude(require('./product/clickAndCollect'));
});

require('./thirdParty/bodyScrollLock');
require('./thirdParty/bootstrap');
require('./thirdParty/sticky-kit');
require('./thirdParty/owl.carousel');
require('./thirdParty/lazysizes');
require('./components/spinner');
require('./components/customCarousels');
require('./thirdParty/jquery.validate.min');
require('./thirdParty/owl.carousel2.thumbs.min_in_webpack');
// require('./thirdParty/scandit-engine-sdk.min');
// require('./thirdParty/html5-qrcode.min')
