'use strict';

module.exports = function () {
	//Set initial consent on document ready
	setConsent();
	
	//Bind eventListener to consentGiven if user updates through popup
	window.addEventListener('CookieInformationConsentGiven', function (event) {
		setConsent();
	}, false); 
	
	//Function for setting consent on session
	function setConsent(){
		if(typeof CookieInformation !== "undefined"){
			var url = $('#consentActionUrl_reject').attr('data-action');
			
			if(CookieInformation.getConsentGivenFor('cookie_cat_statistic')) {
				url = $('#consentActionUrl_accept').attr('data-action');
			}
			
			if(url){
				$.ajax({
			        url: url,
			        method: 'GET',
					success: function (data) {
						if($('.klaviyo-footer-container').length && !$('.klaviyo-footer-container').html().toLowerCase().includes('klaviyo') && data.template){
							$('.klaviyo-footer-container').append(data.template)
						}
					}
			    });	
			}	
		}
	}
};
